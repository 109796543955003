//
// RD Navbar Default Aside Left
// --------------------------------------------------

%rd-navbar-default-aside-left{
  .rd-navbar-nav-wrap{
    @include rd-navbar-sidebar(280px, "left");
    padding: $rd-navbar-fixed-height 0 0;
    &.active{
      transform: translateX(0);
    }
    color: $black;
    background: $white;
  }
}

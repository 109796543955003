//
// Variables 
// --------------------------------------------------

@import "bootstrap/variables";

//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$black:             #191919;
$gray-900:          #212121;
$gray-800:          #434345;
$gray-700:          #9b9b9b;
$gray-500:          #d9d9d9;
$gray-300:          #f2f2f2;
$gray-200:          #f5f5f5;
$gray-100:          #f7f7f7;

$primary:           #ff5f6d;
$success:           #abcc37;
$info:              #79ccf2;
$warning:           #f5bf2f;
$danger:            #f54b0f;
$deluge:            #8668ad;

// Derivatives colors
$butterfly-bush:    #695999;
$picton-blue:       #55bbeb;
$dollar-bill:       #84c450;
$neon-carrot:       #F3A43D;
$ku-crimson:        #e50a0a;
$iceberg:           #64aae1;
$macaroni:          #ffc371;
$bondi-blue:        #02AAB0;
$caribbean-green:   #00CDAC;


$white: #fff;

//== Scaffolding
//
//## Settings for some of the most global styles.

$content-bg:            $white;
$header-bg:             $white;
$footer-bg: 			$black;

//** Background color for `<body>`.
$body-bg:               $black;

//** Background color for `<main>`.
$main-bg:               $white;

//** Global text color on `<body>`.
$body-color:            $gray-700;

//** Global textual link color.
$link-color:            inherit;
//** Link hover color set via `darken()` function.
$link-hover-color:      inherit;
//** Link hover decoration.
$link-hover-decoration: underline;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

// Import fonts


$font-family-sans-serif:  'Open Sans', sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-base:        $font-family-sans-serif;
$font-family-accent:      $font-family-sans-serif;


// Template width (w/o units)
$template-width:          1920;

// Template width var for resizing
$template-width-min:      ($template-width+1)*1px;
$layout-boxed-width:      1920px;


// Base FZ & LH (w/o units)
$base-text-size:          16;
$base-lh-size:            24;

$base-vm-size:            (($base-text-size*100)/$template-width)*1vw;


$font-size-base:          $base-text-size * 1px;
$font-size-lg:         ceil(($font-size-base * 1.0625)); // ~17px
$font-size-sm:         ceil(($font-size-base * 0.9375)); // ~15px

$font-weight-base:        400;

//PSD FZ headers /FOR EDIT (w/o units)
$Size-h1:                 64;
$Size-h2:                 45;
$Size-h3:                 30;
$Size-h4:                 24;
$Size-h5:                 22;
$Size-h6:                 18;

//PSD LH headers /FOR EDIT (w/o units)
$Lh-h1:                   60;
$Lh-h2:                   54;
$Lh-h3:                   36;
$Lh-h4:                   30;
$Lh-h5:                   27;
$Lh-h6:                   25;


//Calculating FZ headers
$h1-font-size:            $Size-h1*1px;
$h2-font-size:            $Size-h2*1px;
$h3-font-size:            $Size-h3*1px;
$h4-font-size:            $Size-h4*1px;
$h5-font-size:            $Size-h5*1px;
$h6-font-size:            $Size-h6*1px;



//** line-height for headers /FOR EDIT
// $font-lh-h_: floor((PSD l-h / $h1-font-size));

$h1-line-height:              ($Lh-h1 / $Size-h1);
$h2-line-height:              ($Lh-h2 / $Size-h2);
$h3-line-height:              ($Lh-h3 / $Size-h3);
$h4-line-height:              ($Lh-h4 / $Size-h4);
$h5-line-height:              ($Lh-h5 / $Size-h5);
$h6-line-height:              ($Lh-h6 / $Size-h6);

$line-height-base:        ($base-lh-size / $base-text-size); // lh/fz-base

$line-height-computed:    $base-lh-size*1px;

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit;
$headings-font-weight:    400;
$headings-line-height:    1.1;
$headings-color:          $black;


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.
$icon-font-path: "../fonts/";

//** File name for all font files.
$icon-font-name:           "glyphicons-halflings-regular";
$icon-font-awesome:        "fontawesome-webfont";
$icon-material-design:     "materialdesignicons-webfont";

//** Element ID within SVG icon file.
$icon-font-svg-id:         "glyphicons_halflingsregular";
$icon-font-awesome-id:     "fontawesomeregular";
$icon-material-design-id:  "materialdesigniconsregular";

//** Icons font family options
$icon-font-material-design: "Material Design Icons";
$icon-font-fontawesome:     "FontAwesome";


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     9px;
$padding-base-horizontal:   16px;

$padding-large-vertical:    17px;
$padding-large-horizontal:  24px;

$padding-small-vertical:    7px;
$padding-small-horizontal:  12px;

$padding-xs-vertical:       6px;
$padding-xs-horizontal:     16px;

$line-height-large:         1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5;

$border-radius:        5px;
$border-radius-large:       10px;
$border-radius-small:       3px;

$border-width-base:         2px;
$border-width-large:        4px;
$border-width-small:        1px;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    $white;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $primary;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base:          4px;
//** Carets increase slightly in size for larger components.
$caret-width-large:         5px;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:                  8px;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:        5px;

//** Default background color used for all tables.
$table-bg:                            transparent;
//** Background color used for `.table-striped`.
$table-bg-accent:                     #f9f9f9;
//** Background color used for `.table-hover`.
$table-bg-hover:                      #f5f5f5;
$table-bg-active:                     $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color:                  #ddd;

//** Table Striped
$table-striped-odd-background:        $gray-100;

//** Custom Tables
$table-custom-odd-background:         $gray-100;
//** large table from 768
$table-custom-large-column-padding:   8px 18px;
//** small table to 767
$table-custom-small-column-padding:   6px;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-size:               14px;
$btn-lh-size:                 28px;
$btn-font-weight:             900;

$btn-font-size-large:         17px;
$btn-lh-size-large:           32px;
$btn-font-weight-large:       $btn-font-weight;

$btn-font-size-small:         12px;
$btn-lh-size-small:           24px;
$btn-font-weight-small:       $btn-font-weight;

$btn-font-size-xs:            12px;
$btn-lh-size-xs:              20px;
$btn-font-weight-xs:          $btn-font-weight;
//**  btn-link
$btn-link-padding-left:       12px;
$btn-link-padding-right:      12px;
$btn-link-weight:             900;
$btn-link-color:              $gray-800;
$btn-link-line-height:        1.9;
//**  btn-link after
$btn-link-after-background:   linear-gradient(90deg, $primary 0%, $dollar-bill 100%);
//**  btn more
$btn-more-size:               17px;
$btn-more-line-height:        18px;
$btn-more-weight:             700;
//**  btn more icon
$btn-more-icon-size:          18px;
$btn-more-icon-line-height:   $btn-more-icon-size;
$btn-more-icon-color:         $picton-blue;
//**  btn more icon hover
$btn-more-icon-hover-color:   $primary;
//**  btn icon
$btn-icon-padding:            17px;
$btn-icon-size:               28px;
$btn-icon-offset:             10px;
//**  btn icon xs
$btn-icon-xs-padding:         10px;
$btn-icon-xs-size:            20px;
$btn-icon-xs-offset:          10px;
//**  btn icon sm
$btn-icon-sm-padding:         10px;
$btn-icon-sm-size:            24px;
$btn-icon-sm-offset:          8px;
//**  btn icon lg
$btn-icon-lg-padding:         30px;
$btn-icon-lg-size:            32px;
$btn-icon-lg-offset:          14px;


//== Icons
//
//##

$icon-default-size:             54px;
$icon-default-weight:           400;
$icon-default-line-height:      56px;
$icon-circle-border-radius:     50%;
$icon-rounded-border-radius:    $border-radius;
$icon-outlined-border:          2px solid $gray-200;
//** Icon shapes
$icon-shapes-width:             70px;
$icon-shapes-height:            70px;
$icon-shapes-line-height:       70px;
$icon-shapes-size:              32px;
$icon-shapes-border:            2px solid;
//** Icon sizes
//** xxs
$icon-xxs-size:                 18px;
$icon-xxs-line-height:          18px;
//** xs
$icon-xs-size:                  32px;
$icon-xs-line-height:           32px;
//** sm
$icon-sm-size:                  42px;
$icon-sm-line-height:           42px;
//** lg
$icon-lg-size:                  72px;
$icon-lg-line-height:           72px;
//** xlg
$icon-xlg-size:                 96px;
$icon-xlg-line-height:          96px;
//** Icon Appearance
//** icon default
$icon-default-color:            $gray-800;
$icon-default-border-color:     $gray-500;
//** icon darker
$icon-darker-color:             $gray-800;
$icon-darker-border-color:      $gray-800;
//** icon primary
$icon-primary-color:            $primary;
$icon-primary-border-color:     $primary;
//** icon deluge
$icon-deluge-color:             $deluge;
$icon-deluge-border-color:      $deluge;
//** icon picton blue
$icon-picton-blue-color:        $picton-blue;
$icon-picton-blue-border-color: $picton-blue;
//** icon success
$icon-success-color:            $success;
$icon-success-border-color:     $success;
//** icon warning
$icon-warning-color:            $warning;
$icon-warning-border-color:     $warning;
//** icon danger
$icon-danger-color:             $danger;
$icon-danger-border-color:      $danger;
//** icon info
$icon-info-color:               $info;
$icon-info-border-color:        $info;


//== Dropcaps
//
//##

$dropcap-color:                 $gray-700;
$dropcap-size:                  120px;
$dropcap-weight:                300;
$dropcap-line-height:           0.7;

//== Shadows
//
//##

$shadow-area-ambient:           0px 0px 1px 0 rgba(0, 0, 0, 0.15);
$shadow-area-xxs:               1px 1px 1px rgba(0, 0, 0, 0.15);
$shadow-area-xs:                0 1px 4px 0 rgba(0, 0, 0, 0.15);
$shadow-area-sm:                0 1px 10px 0 rgba(0, 0, 0, 0.15);
$shadow-area-md:                0 3px 11px 0 rgba(0, 0, 0, 0.15);
$shadow-area-lg:                0 5px 23px 0 rgba(0, 0, 0, 0.15);
$shadow-area-xl:                0 12px 36px 0 rgba(0, 0, 0,.15);
$shadow-area-variant-2:         0 0px 20px 2px rgba(0, 0, 0,.1);

//== Quotes
//
//##

//** all types quotes braces
$quote-braces-icon:                         "\f373";
$quote-braces-font:                         $icon-font-material-design;
$quote-braces-size:                         54px;
$quote-braces-line-height:                  54px;

//** Quote Classic
$quote-braces-color:                        $gray-200;
$quote-classic-body-padding:                20px 20px 40px;
//** quote classic description
$quote-classic-desc-size:                   13px;
$quote-classic-desc-weight:                 400;
//** quote classic boxed
$quote-classic-boxed-body-padding:          24px 20px 0 27px;
$quote-classic-boxed-body-offset-bottom:    56px;
$quote-classic-boxed-body-border-radius:    $border-radius-small;
$quote-classic-boxed-body-border:           1px solid $gray-200;
//** Quote Classic Boxed v2
$quote-classic-boxed-2-body-padding:        60px 25px 20px 30px;
$quote-classic-boxed-2-body-background:     $white;
$quote-classic-boxed-2-body-border-radius:  $border-radius-small;
$quote-classic-boxed-2-body-box-shadow:     $shadow-area-md;
$quote-classic-boxed-2-braces-icon-color:   $gray-500;
//** Quote Slider v2
$quote-slider-2-author-color:               $danger;
//** Quote Slider v3
$quote-slider-3-braces-icon-color:          $picton-blue;
//** Quote Outboxed
$quote-outboxed-body-padding:               26px 21px 25px 30px;
$quote-outboxed-body-background:            $gray-200;
$quote-outboxed-body-border-radius:         $border-radius-small;
$quote-outboxed-braces-icon-color:          $gray-500;

//== Forms
//
//##

//** Form Control color options
$input-bg:                       $white;
$input-bg-disabled:              $gray-200;
$input-color:                    $gray-900;
$input-border-color:             $gray-500;

$input-focus-bg: $input-bg;
$input-focus-border-color: $input-border-color;
$input-focus-color: $input-color;
$input-btn-focus-box-shadow: none;


//** Form Control impressed color options
$input-impressed-bg: $gray-100;

//** Form Control text styling options
$input-font-size:                13px;

//** Form Control border radius
$input-border-color-radius:            $border-radius;
$input-border-color-radius-large:      $border-radius-large;
$input-border-color-radius-small:      $border-radius-small;

//** Border color for inputs on focus
$input-border-color-focus:             $gray-500;

//** Placeholder color options
$input-color-placeholder:        $input-color;
$input-color-placeholder-focus:  $picton-blue;

//** Form Control sizing options
$input-height:              $btn-lh-size + ($padding-base-vertical + $border-width-base)*2;
$input-height-lg:             $btn-lh-size-large + ($padding-large-vertical + $border-width-base)*2;
$input-height-sm:             $btn-lh-size-small + ($padding-small-vertical + $border-width-small)*2;

//** Form Group spacing options
$form-wrap-margin-bottom:       10px;

//** Legend styling options
$legend-color:                   $gray-700;
$legend-border-color:            $gray-200;

//** Input Group Addon styling options
$input-group-addon-color:        $white;
$input-group-addon-bg:           $gray-900;
$input-group-addon-border-color: $gray-900;

//** Form label styling options
$form-label-position-top:          25px;
$form-label-focus-position-top:    3px;
//** sm input size variation
$form-label-sm-position-top:       20px;
$form-label-sm-focus-position-top: -1px;
//** lg input size variation
$form-label-lg-position-top:       35px;
$form-label-lg-focus-position-top: 8px;

//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed;

//** Checkbox custom styling options
$checkbox-icon:                   "\f222";
$checkbox-icon-font:              $icon-font-material-design;
$checkbox-checked-color:          $gray-800;

//** Radio custom styling options
$radio-checked-color:             $gray-800;
$radio-input-width:               14px;
$radio-input-height:              14px;
$radio-input-background:          $gray-200;
$radio-input-border-radius:       50%;
$radio-input-padding:             3px;
$radio-input-shadow:              inset $shadow-area-xxs, inset 0 0 0 3px $gray-200;
//** checked state
$radio-input-checked-background:  $gray-800;

//** Textarea default
$form-textarea-default-height:       170px;
$form-textarea-default-min-height:   50px;
$form-textarea-default-max-height:   370px;

//** Form validation styling options
$form-validation-position-top:           100%;
$form-validation-size:                   10px;
$form-validation-line-height:            $form-validation-size;
$form-validation-offset-top:             2px;
$form-validation-error-color:            $ku-crimson;
$form-validation-success-color:          $dollar-bill;
//** inside input
$form-validation-inside-position-top:    2px;
$form-validation-inside-position-left:   12px;
$form-validation-inside-bg:              $white;

//** Form control feedback styling options
$form-input-feedback-size:             20px;
$form-input-feedback-padding-right:    50px;
//** sm input size variation
$form-input-sm-feedback-padding-right: 40px;
//** lg input size variation
$form-input-lg-feedback-padding-right: 70px;

//** Search form styling options
//** submit btn
$form-search-btn-size:                   24px;
$form-search-btn-color:                  $gray-800;
//** focus state
$form-search-btn-focus-color:            $primary;
//** label
$form-search-label-color:                $gray-900;
//** input
$form-search-input-padding-right:        35px;
$form-search-input-color:                $gray-900;


//== Dropdowns
//

//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg:                    #fff;

//** Divider color for between dropdown items.
$dropdown-divider-bg:            $gray-500;

//** Dropdown link text color.
$dropdown-link-color:            $gray-800;

//** Hover color for dropdown links.
$dropdown-link-hover-color:      $gray-800;

//** Hover background for dropdown links.
$dropdown-link-hover-bg:         $gray-200;

//** Active dropdown menu item text color.
$dropdown-link-active-color:     $component-active-color;

//** Active dropdown menu item background color.
$dropdown-link-active-bg:        $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   $gray-500;

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray-700;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color:           $gray-800;

//** The dropdown menu
$dropdown-menu-size:             13px;
$dropdown-menu-border-radius:    $border-radius-small;
//** Links within the dropdown menu
$dropdown-menu-item-padding:     5px 20px;
//** Dropdown section headers
$dropdown-header-size:           12px;
$dropdown-header-padding:        8px 20px 3px;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            1000;
$zindex-dropdown:          1000;
$zindex-popover:           1060;
$zindex-tooltip:           1070;
$zindex-navbar-fixed:      1030;
$zindex-modal-background:  1040;
$zindex-modal:             1050;


//
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
	xs: 0,
	sm: 480px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px
);


//== Navbar
//
//##

// $navbar-font-size: 16;
// $navbar-lh-size: 18;

// Basics of a navbar
$navbar-height:                            52px;
$navbar-margin-bottom:                     $line-height-computed;
$navbar-border-radius:                     $border-radius;
$navbar-padding-horizontal:                floor(($grid-gutter-width / 2));
$navbar-padding-vertical:                  (($navbar-height - $line-height-computed) / 2);
$navbar-collapse-max-height:               340px;

$navbar-default-color:                     $gray-800;
$navbar-default-bg:                        $white;

// Navbar links
$navbar-default-link-color:                $gray-800;
$navbar-default-link-hover-color:          $gray-800;
$navbar-default-link-hover-bg:             $gray-200;
$navbar-default-link-active-color:         $white;
$navbar-default-link-active-bg:            $primary;
$navbar-default-link-disabled-color:       $gray-500;
$navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd;
$navbar-default-toggle-icon-bar-bg:        #888;
$navbar-default-toggle-border-color:       #ddd;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:     $dollar-bill;
$state-success-bg:       $success;
$state-success-border:   $success;

$state-info-text:        $iceberg;
$state-info-bg:          $info;
$state-info-border:      $info;

$state-warning-text:     $neon-carrot;
$state-warning-bg:       $warning;
$state-warning-border:   $warning;

$state-danger-text:      $ku-crimson;
$state-danger-bg:        $danger;
$state-danger-border:    $danger;


//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width:           200px;
//** Tooltip text color
$tooltip-color:               #fff;
//** Tooltip background color
$tooltip-bg:                  #000;
$tooltip-opacity:             .9;

//** Tooltip arrow width
$tooltip-arrow-width:         5px;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg;

//== UI ToTop
//
//##

$to-top-color:              $white;
$to-top-background:         $primary;
$to-top-box-shadow:         $shadow-area-sm;
$to-top-position-right:     15px;
$to-top-position-bottom:    15px;
$to-top-hover-color:        $white;
$to-top-hover-background:   $macaroni;
//** 480
$to-top-position-xs-right:  40px;
$to-top-position-xs-bottom: 40px;

//== Popovers
//
//##

//** Popover body background color
$popover-bg:                          #fff;
//** Popover maximum width
$popover-max-width:                   276px;
//** Popover border color
$popover-border-color:                rgba(0,0,0,.2);
//** Popover fallback border color
$popover-fallback-border-color:       #ccc;

//** Popover title background color
$popover-title-bg:                    darken($popover-bg, 3%);

//** Popover arrow width
$popover-arrow-width:                 10px;
//** Popover arrow color
$popover-arrow-color:                 $popover-bg;

//** Popover outer arrow width
$popover-arrow-outer-width:           ($popover-arrow-width + 1);
//** Popover outer arrow color
$popover-arrow-outer-color:           fade_in($popover-border-color, 0.05);
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%);


//== Labels
//
//##

//** Default label background color
$label-default-bg:                    $gray-500;
//** Primary label background color
$label-primary-bg:                    $primary;
//** Success label background color
$label-success-bg:                    $success;
//** Info label background color
$label-info-bg:                       $info;
//** Warning label background color
$label-warning-bg:                    $warning;
//** Danger label background color
$label-danger-bg:                     $danger;
//** Deluge label background color
$label-deluge-bg:                     $deluge;

//** Default label text color
$label-color:                         #fff;
//** Default text color of a linked label
$label-link-hover-color:              #fff;
//**
$label-padding:                       .2em .6em .2em;
//** Labels Custom
$label-custom-hover:                  $white;
$label-custom-padding:                8px;
$label-custom-size:                   8px;
$label-custom-weight:                 900;
$label-custom-line-height:            1;
$label-custom-title-offset-left:      10px;
$label-ellipsis-custom-border-radius: 10px;
$label-rounded-custom:                3px;
//** labels custom sizing
$label-xs-custom-padding:             3px 5px;
$label-sm-custom-padding:             7px;
$label-lg-custom-padding:             9px 10px;
$label-lg-custom-padding:             9px 10px;
$label-lg-custom-size:                12px;
$label-xl-custom-padding:             10px 12px;
$label-xl-custom-size:                12px;


//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding:                  15px;

//** Padding applied to the modal title
$modal-title-padding:                  15px;
//** Modal title line-height
$modal-title-line-height:              $line-height-base;

//** Background color of modal content area
$modal-content-bg:                     #fff;
//** Modal content border color
$modal-content-border-color:           rgba(0,0,0,.2);
//** Modal content border color **for IE8**
$modal-content-fallback-border-color:  #999;

//** Modal backdrop background color
$modal-backdrop-bg:                    #000;
//** Modal backdrop opacity
$modal-backdrop-opacity:               .5;
//** Modal header border color
$modal-header-border-color:            #e5e5e5;
//** Modal footer border color
$modal-footer-border-color:            $modal-header-border-color;

$modal-lg:                             900px;
$modal-md:                             600px;
$modal-sm:                             300px;


//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:                           15px;
$alert-border-radius:                     $border-radius;
$alert-link-font-weight:                  bold;

$alert-success-bg:                        $state-success-bg;
$alert-success-text:                      $state-success-text;
$alert-success-border:                    $state-success-border;

$alert-info-bg:                           $state-info-bg;
$alert-info-text:                         $state-info-text;
$alert-info-border:                       $state-info-border;

$alert-warning-bg:                        $state-warning-bg;
$alert-warning-text:                      $state-warning-text;
$alert-warning-border:                    $state-warning-border;

$alert-danger-bg:                         $state-danger-bg;
$alert-danger-text:                       $state-danger-text;
$alert-danger-border:                     $state-danger-border;

//** Alert Classic
$alert-classic-background-color:          $gray-200;

$alert-classic-title-weight:              700;

$alert-classic-padding:                   17px 22px 17px;
$alert-classic-offset-top:                10px;
$alert-classic-border-left-width:         3px;
$alert-classic-border-left-style:         solid;

//** Alert Classic Styles
//** alert danger
$alert-classic-danger-title-color:        $ku-crimson;
$alert-classic-danger-border-left-color:  $danger;
//** alert info
$alert-classic-info-title-color:          $iceberg;
$alert-classic-info-border-left-color:    $info;
//** alert success
$alert-classic-success-title-color:       $dollar-bill;
$alert-classic-success-border-left-color: $success;
//** alert warning
$alert-classic-warning-title-color:       $neon-carrot;
$alert-classic-warning-border-left-color: $warning;

//** Alert Classic Sizing
$alert-classic-sm-padding-top:            7px;
$alert-classic-sm-padding-bottom:         7px;
$alert-classic-sm-border-left-width:      2px;
$alert-classic-lg-padding-top:            23px;
$alert-classic-lg-padding-bottom:         28px;
$alert-classic-lg-border-left-width:      4px;

//** Snackbars
$snackbars-color:                         $white;
$snackbars-background-color:              $gray-900;
$snackbars-padding:                       9px 16px;


//== Progress bars
//
//##

//** Progress bar sizing options
$progress-font-weight:                  700;
$progress-font-size:                    13px;
$progess-height:                        20px;
//** Background color of the whole progress component
$progress-bg:                           #f5f5f5;
//** Progress bar text color
$progress-bar-color:                    #fff;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius:                $border-radius;
//** Default progress bar color
$progress-bar-bg:                       $primary;
//** Success progress bar color
$progress-bar-success-bg:               $success;
//** Warning progress bar color
$progress-bar-warning-bg:               $warning;
//** Danger progress bar color
$progress-bar-danger-bg:                $danger;
//** Info progress bar color
$progress-bar-info-bg:                  $info;
//** Dark progress bar color
$progress-bar-dark-bg:                  $gray-900;


//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg:                   $white;

//** `.list-group-item` border color
$list-group-border:               $gray-500;

//** List group border radius
$list-group-border-radius:        $border-radius-small;

//** Background color of single list items on hover
$list-group-hover-bg:             $gray-100;

//** Text color of active list items
$list-group-active-color:         $white;

//** Background color of active list items
$list-group-active-bg:            $primary;

//** Border color of active list elements
$list-group-active-border:        $primary;

//** Text color for content within active list items
$list-group-active-text-color:    $white;

//** Text color of disabled list items
$list-group-disabled-color:       $gray-300;

//** Background color of disabled list items
$list-group-disabled-bg:          $gray-200;

//** Text color for content within disabled list items
$list-group-disabled-text-color:  $list-group-disabled-color;

$list-group-link-color:           #555;
$list-group-link-hover-color:     $list-group-link-color;
$list-group-link-heading-color:   #333;


//== Panels
//
//##

$panel-bg:                    #fff;
$panel-size:                  16px;
$panel-weight:                700;
$panel-body-padding:          15px;
$panel-heading-padding:       10px 15px;
$panel-footer-padding:        $panel-heading-padding;
$panel-border-radius:         $border-radius;

//** Border color for elements within panels
$panel-inner-border:          #ddd;
$panel-footer-bg:             #f5f5f5;

$panel-default-text:          $gray-800;
$panel-default-border:        #ddd;
$panel-default-heading-bg:    $gray-200;

$panel-primary-text:          #fff;
$panel-primary-border:        $primary;
$panel-primary-heading-bg:    $primary;

$panel-success-text:          $state-success-text;
$panel-success-border:        $state-success-border;
$panel-success-heading-bg:    $state-success-bg;

$panel-info-text:             $state-info-text;
$panel-info-border:           $state-info-border;
$panel-info-heading-bg:       $state-info-bg;

$panel-warning-text:          $state-warning-text;
$panel-warning-border:        $state-warning-border;
$panel-warning-heading-bg:    $state-warning-bg;

$panel-danger-text:           $state-danger-text;
$panel-danger-border:         $state-danger-border;
$panel-danger-heading-bg:     $state-danger-bg;


//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding:                               0;
//** Thumbnail background color
$thumbnail-bg:                                    $body-bg;
//** Thumbnail border radius
$thumbnail-border-radius:                         $border-radius;
//** Custom text color for thumbnail captions
$thumbnail-caption-color:                         $body-color;
//** Padding around the thumbnail caption
$thumbnail-caption-padding:                       20px;

//== Wells
//
//##

$well-bg:      #f5f5f5;
$well-border:  darken($well-bg, 7%);


//== Badges
//
//##

$badge-padding:               2px 5px;
$badge-color:                 $white;
//** Linked badge text color on hover
$badge-link-hover-color:      $white;
$badge-bg:                    $primary;
//** Badge text color in active nav link
$badge-active-color:          $primary;
//** Badge background color in active nav link
$badge-active-bg:             $white;
$badge-font-weight:           bold;
$badge-font-size:             12px;
$badge-line-height:           1;
$badge-border-radius:         10px;
//** Badge inside pills
$badge-pills-offset-left:     5px;
$badge-pills-offset-bottom:   3px;


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical:             8px;
$breadcrumb-padding-horizontal:           15px;
//** Breadcrumb background color
$breadcrumb-bg:                           #f5f5f5;
//** Breadcrumb text color
$breadcrumb-color:                        #ccc;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:                 $gray-300;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator:                    "/";
//** Breadcrumb Classic
$breadcrumb-classic-color:                $white;
$breadcrumb-classic-position:             relative;
$breadcrumb-classic-after-position:       absolute;
$breadcrumb-classic-after-position-right: 0;
$breadcrumb-classic-after-position-left:  0;
$breadcrumb-classic-after-width:          100%;
$breadcrumb-classic-after-height:         100%;
$breadcrumb-classic-animation:            fadeBoth 6s infinite;
//** Breadcrumb Default
$breadcrumb-default-color:                $gray-700;
$breadcrumb-default-hover:                $picton-blue;
$breadcrumb-default-background:           $gray-100;
$breadcrumb-default-inset:                28px 15px;
$breadcrumb-default-offset-left:          3px;
$breadcrumb-default-size:                 15px;
//- icon styling
$breadcrumb-default-icon-color:           $gray-700;
$breadcrumb-default-icon-inset-right:     4px;
$breadcrumb-default-icon:                 "\f234";
$breadcrumb-default-icon-font-family:     "Material Design Icons";


//== Carousel
//
//##

$carousel-text-shadow:              0 1px 2px rgba(0,0,0,.6);
$carousel-control-color:            #fff;
$carousel-control-width:            15%;
$carousel-control-opacity:          .5;
$carousel-control-font-size:        20px;
$carousel-indicator-active-bg:      #fff;
$carousel-indicator-border-color:   #fff;
$carousel-caption-color:            #fff;


//== Close
//
//##

$close-font-weight:   bold;
$close-color:         #000;
$close-text-shadow:   0 1px 0 #fff;


//== Code
//
//##

$code-color:                  #c7254e;
$code-bg:                     #f9f2f4;

$kbd-color:                   #fff;
$kbd-bg:                      #333;

$pre-bg:                      #f5f5f5;
$pre-color:                   $gray-700;
$pre-border-color:            #ccc;
$pre-scrollable-max-height:   340px;


//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//** Text muted color
$text-muted:                  $gray-500;
//** Abbreviations and acronyms border color
$abbr-border-color:           $gray-300;
//** Headings small color
$headings-small-color:        $gray-300;
//** Blockquote small color
$blockquote-small-color:      $gray-300;
//** Blockquote font size
$blockquote-font-size:        ($font-size-base * 1.25);
//** Blockquote border color
$blockquote-border-color:     $gray-200;
//** Page header border color
$page-header-border-color:    $gray-200;
//** Width of horizontal description list titles
$dl-horizontal-offset:        $component-offset-horizontal;
//** Point at which .dl-horizontal becomes horizontal
// TODO $dl-horizontal-breakpoint:    $grid-float-breakpoint;
//** Horizontal line color.
$hr-border:                   $gray-200;

//== Scroll to
//
//##

$scrollToNext-color:          $white;
$scrollToNext-hover-color:    $white;
$scrollToNext-width:          50px;
$scrollToNext-height:         50px;
$scrollToNext-size:           24px;
$scrollToNext-line-height:    46px;
$scrollToNext-border-radius:  50%;


//== RD Navbar
//
//##

//** Base styles
$rd-navbar-size:            13px;
$rd-navbar-line-height:     24px;
$rd-navbar-weight:          900;
$rd-navbar-text-transform:  uppercase;

// Common RD navbar components styling options
//##
//** RD Navbar Cart
//** Cart list
$rd-navbar-cart-list-products-padding:            0 15px 15px 0;
//** cart product title
$rd-navbar-cart-product-title-size:               12px;
$rd-navbar-cart-product-title-offset-bottom:      5px;
//** cart product controls
$rd-navbar-cart-product-remove-size:              12px;
//** cart product price
$rd-navbar-cart-product-price-size:               12px;
//** Cart total
$rd-navbar-cart-total-title-size:                 14px;
$rd-navbar-cart-total-price-size:                 14px;
$rd-navbar-cart-total-offset-top:                 20px;
//** cart Dark theme
$rd-navbar-dark-cart-product-remove-color:        $gray-700;
$rd-navbar-dark-cart-product-remove-hover-color:  $white;
$rd-navbar-dark-cart-product-border-bottom:       1px solid $gray-900;
$rd-navbar-dark-cart-product-image-background:    $gray-200;
$rd-navbar-dark-cart-product-price-color:         $gray-700;
$rd-navbar-dark-cart-dropdown-background:         darken(saturate(adjust_hue($black, 0), 0%), 1.5686274509803937%);
//** common total title, price, product-title
$rd-navbar-dark-cart-total-price-product-color:   $white;
//** cart Light theme
$rd-navbar-light-cart-product-remove-color:       $gray-700;
$rd-navbar-light-cart-product-remove-hover-color: $gray-900;
$rd-navbar-light-cart-product-border-bottom:      1px solid $gray-200;
$rd-navbar-light-cart-product-image-background:   $gray-200;
$rd-navbar-light-cart-product-price-color:        $gray-700;
$rd-navbar-light-cart-dropdown-background:        $white;
//** common total title, price, product-title
$rd-navbar-light-cart-total-price-product-color:  $gray-900;

//** RD Navbar Default Panel
$rd-navbar-panel-toggle-color:                          $white;
$rd-navbar-panel-cart-icon-color:                       $white;
$rd-navbar-panel-cart-icon-state-before:                '\f206';
$rd-navbar-panel-cart-icon-state-after:                 '\f69a';
//** dark panel theme
$rd-navbar-dark-panel-background:                       $black;
$rd-navbar-dark-cart-color:                             $white;
//** light panel theme
$rd-navbar-light-background:                            $white;
$rd-navbar-light-toggle-background:                     $gray-900;
$rd-navbar-light-cart-color:                            $gray-900;
//** RD Navbar Aside Right
$rd-navbar-aside-right-cart-dropdown-width:             280px;
$rd-navbar-aside-right-cart-dropdown-padding:           70px 15px 0;
//** dark aside right theme
$rd-navbar-dark-aside-right-cart-dropdown-color:        $white;
$rd-navbar-dark-aside-right-cart-dropdown-background:   $black;
//** light aside right theme
$rd-navbar-light-aside-right-cart-dropdown-color:       $gray-900;
$rd-navbar-light-aside-right-cart-dropdown-background:  $white;
//** RD Navbar Default Aside Left
$rd-navbar-aside-left-padding:                          10px 0;
// dark aside left theme
$rd-navbar-dark-aside-left-color:                       $white;
$rd-navbar-dark-aside-left-background:                  $black;
// light aside left theme
$rd-navbar-light-aside-left-color:                      $black;
$rd-navbar-light-aside-left-background:                 $white;

//** RD Navbar Default Mobile Search
//** dark default mobile search theme
$rd-navbar-dark-mobile-search-form-lable-focus-color:         $gray-700;
$rd-navbar-dark-mobile-search-form-search-submit-color:       $gray-800;
$rd-navbar-dark-mobile-search-form-search-input-color:        $white;
$rd-navbar-dark-mobile-search-form-search-input-background:   $gray-900;
//** dark default mobile search theme
$rd-navbar-light-mobile-search-form-lable-focus-color:        $gray-700;
$rd-navbar-light-mobile-search-form-search-submit-color:      $gray-800;
$rd-navbar-light-mobile-search-form-search-input-color:       $gray-900;
$rd-navbar-light-mobile-search-form-search-input-background:  $gray-100;

//** RD Navbar First level of Menu
//** dark first level of menu theme
$rd-navbar-dark-menu-first-lvl-list-item-link-color:   $white;
//** light first level of menu theme
$rd-navbar-light-menu-first-lvl-list-item-link-color:  $gray-900;

//** RD Navbar Menu Dropdown
//** common styles: navbar dropdown, navbar megamenu, navbar cart dropdown
$rd-navbar-menu-dropdown-lists-second-lvl-border-top:                               1px solid $primary;
//** common styles: navbar dropdown, navbar megamenu
$rd-navbar-menu-dropdown-lists-dropdown-megamenu-item-link-padding:                 8px 30px 8px 20px;
$rd-navbar-menu-dropdown-lists-dropdown-megamenu-item-caret-icon:                   '\f238';
$rd-navbar-menu-dropdown-lists-dropdown-megamenu-item-caret-icon-size:              22px;
$rd-navbar-menu-dropdown-lists-dropdown-megamenu-item-caret-icon-family:            'Material Design Icons';
$rd-navbar-menu-dropdown-lists-dropdown-megamenu-item-caret-icon-line-height:       34px;
//** megamenu icon caret
$rd-navbar-menu-dropdown-megamenu-active-item-hover-color:                          $primary;
//** dark menu dropdown theme
//** common styles: navbar dropdown, navbar megamenu
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-background:                   darken(saturate(adjust_hue($black, 0), 0%), 1.5686274509803937%);
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-active-item-color:            $gray-800;
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-link-color:              $white;
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-icon-color:              $gray-700;
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-hover-link-color:        $white;
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-hover-link-background:   $black;
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-hover-link-after-color:  $white;
//** dark megamenu theme
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-before-border-color:          $gray-900;
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-color:                   $gray-700;
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-hover-color:             $white;
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-active-color:            $primary;
$rd-navbar-dark-menu-dropdown-lists-dropdown-megamenu-item-active-hover-color:      $primary;
//** light menu dropdown theme
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-background:                  $white;
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-active-item-color:           $gray-800;
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-link-color:             $gray-800;
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-icon-color:             $gray-500;
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-hover-link-color:       $white;
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-hover-link-background:  $black;
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-hover-link-after-color: $white;
//** light megamenu theme
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-before-border-color:         $gray-500;
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-color:                  $gray-700;
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-active-color:           $primary;
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-active-hover-color:     $primary;
$rd-navbar-light-menu-dropdown-lists-dropdown-megamenu-item-hover-color:            $gray-900;

//** RD Navbar Mobile menu
//** mobile menu list
$rd-navbar-mobile-menu-list-offset-top:                                     15px;
//** mobile menu list item
$rd-navbar-mobile-menu-list-item-offset-top:                                5px;
//** mobile menu list item link
$rd-navbar-mobile-menu-list-item-link-padding:                              8px 30px 8px 0;
//** mobile menu list item label
$rd-navbar-mobile-menu-list-item-label-offset-left:                         5px;
//** mobile menu list item icon
$rd-navbar-mobile-menu-list-item-icon-size:                                 20px;
$rd-navbar-mobile-menu-list-item-icon-offset-right:                         5px;
//** mobile menu list item arrow
$rd-navbar-mobile-menu-list-item-arrow-width:                               35px;
$rd-navbar-mobile-menu-list-item-arrow-size:                                24px;
$rd-navbar-mobile-menu-list-item-arrow-line-height:                         40px;
$rd-navbar-mobile-menu-list-item-arrow-family:                              'Material Design Icons';
$rd-navbar-mobile-menu-list-item-arrow-content:                             '\f236';
$rd-navbar-mobile-menu-list-item-arrow-active-content:                      '\f239';
//** mobile menu common megamenu, dropdown
$rd-navbar-mobile-menu-dropdown-padding:                                    5px 0;
$rd-navbar-mobile-menu-dropdown-item-link-padding-left:                     25px;
//** dropdown 2lvl
$rd-navbar-mobile-menu-dropdown2-item-link-padding-left:                    35px;
//** dropdown 3lvl
$rd-navbar-mobile-menu-dropdown3-item-link-padding-left:                    45px;
//** mobile menu megamenu
$rd-navbar-mobile-menu-megamenu-tabs-padding-left:                          25px;
$rd-navbar-mobile-menu-megamenu-tabs-content-padding:                       0 10px;
$rd-navbar-mobile-menu-megamenu-tabs-content-list-item-link-padding-left:   35px;
//** mobile menu resp tabs
$rd-navbar-mobile-menu-tabs-accordion-padding:                              10px 30px 10px 15px;
$rd-navbar-mobile-menu-tabs-accordion-line-height:                           20px;
$rd-navbar-mobile-menu-tabs-accordion-offset-bottom:                        5px;
//** mobile menu Dark theme
$rd-navbar-dark-mobile-menu-list-item-link-color:                           $white;
$rd-navbar-dark-mobile-menu-list-item-hover-link-background:                $gray-900;
$rd-navbar-dark-mobile-menu-list-item-hover-toggle-color:                   $white;
$rd-navbar-dark-mobile-menu-list-item-arrow-color:                          $gray-800;
//** mobile menu Dark theme tabs
$rd-navbar-dark-mobile-menu-list-item-tabs-active-background:               $gray-900;
$rd-navbar-dark-mobile-menu-list-item-tabs-active-arrow-color:              $white;
//** mobile menu Dark theme dropdowns
$rd-navbar-dark-mobile-menu-dropdown-item-link-color:                       $white;
$rd-navbar-dark-mobile-menu-submenu-toggle-color:                           $gray-800;
//** mobile menu Light theme
$rd-navbar-light-mobile-menu-list-item-link-color:                          $gray-900;
$rd-navbar-light-mobile-menu-list-item-hover-link-background:               $gray-900;
$rd-navbar-light-mobile-menu-list-item-hover-link-color:                    $white;
$rd-navbar-light-mobile-menu-list-item-hover-toggle-color:                  $white;
$rd-navbar-light-mobile-menu-list-item-arrow-color:                         $gray-800;
//** mobile menu Light theme tabs
$rd-navbar-light-mobile-menu-list-item-tabs-active-background:              $gray-900;
$rd-navbar-light-mobile-menu-list-item-tabs-active-color:                   $white;
$rd-navbar-light-mobile-menu-list-item-tabs-active-arrow-color:             $white;
//** mobile menu Light theme dropdowns
$rd-navbar-light-mobile-menu-dropdown-item-link-color:                      $gray-900;
$rd-navbar-light-mobile-menu-dropdown-item-link-icon-color:                 $gray-500;
$rd-navbar-light-mobile-menu-submenu-toggle-color:                          $gray-700;

//** RD Navbar Search
//** RD Navbar Search Form
$rd-navbar-search-form-input-size:                26px;
$rd-navbar-search-form-input-weight:              900;
$rd-navbar-search-form-input-padding:             0px 13% 0px 85px;
//** RD Navbar Search Form label
$rd-navbar-search-form-label-padding-left:        85px;
$rd-navbar-search-form-label-size:                26px;
$rd-navbar-search-form-label-weight:              900;
//** RD Navbar Search Form label focus
$rd-navbar-search-form-label-focus-size:          18px;
//** RD Navbar Search Toggle
$rd-navbar-search-toggle-offset-left:             25px;
$rd-navbar-search-toggle-icon-color:              $white;
$rd-navbar-search-toggle-icon-size:               24px;
$rd-navbar-search-toggle-open-icon:               '\f43b';
$rd-navbar-search-toggle-close-icon:              '\f24c';
//** Search Form Dark theme
$rd-navbar-dark-search-form-border:               1px solid rgba(255, 255, 255, 0.15);
$rd-navbar-dark-search-form-label-color:          $white;
$rd-navbar-dark-search-form-label-focus-color:    $gray-700;
$rd-navbar-dark-search-form-input-color:          $white;
//** Search Form Light theme
$rd-navbar-light-search-form-border:              1px solid rgba(255, 255, 255, 0.15);
$rd-navbar-light-search-form-label-color:         $gray-900;
$rd-navbar-light-search-form-label-focus-color:   $gray-700;
$rd-navbar-light-search-form-input-color:         $gray-900;

//** RD Navbar Desktop Cart
$rd-navbar-desktop-cart-width:                    240px;
$rd-navbar-desktop-cart-padding:                  13px 15px 15px;
$rd-navbar-desktop-cart-border:                   1px solid $primary;
$rd-navbar-desktop-cart-shadow:                   $shadow-area-sm;
//** RD Navbar Desktop Cart Icon
$rd-navbar-desktop-cart-icon-color:               $white;
$rd-navbar-desktop-cart-icon-open:                '\f206';
$rd-navbar-desktop-cart-icon-close:               '\f69a';
$rd-navbar-desktop-cart-icon-open-size:           24px;
$rd-navbar-desktop-cart-icon-close-size:          22px;

//** RD Navbar Top Panel
$rd-navbar-top-panel-top-links-offset:            36px;
$rd-navbar-top-panel-contact-info-offset:         70px;
$rd-navbar-top-panel-contact-info-icon-size:      20px;
$rd-navbar-top-panel-toggle-icon-state-before:    "\f2ce";
$rd-navbar-top-panel-toggle-icon-state-after:     "\f24c";
$rd-navbar-top-panel-mobile-width:                250px;
$rd-navbar-top-panel-mobile-padding:              20px;
$rd-navbar-top-panel-mobile-margin-bottom:        10px;
$rd-navbar-top-panel-mobile-address-item-margin-bottom:   5px;

//** RD Navbar Top Panel Light Theme
$rd-navbar-light-top-panel-border-bottom-color:          $gray-500;
$rd-navbar-light-top-panel-top-links-color:              $gray-700;
$rd-navbar-light-top-panel-top-links-color-hover:        $primary;
$rd-navbar-light-top-panel-contact-info-color:           $gray-900;
$rd-navbar-light-top-panel-contact-info-icon-color:      $gray-700;
$rd-navbar-light-top-panel-mobile-background:            $white;
$rd-navbar-light-top-panel-toggle-icon-color:            $gray-900;
//** RD Navbar Top Panel Dark Theme
$rd-navbar-dark-top-panel-border-bottom-color:          $gray-800;
$rd-navbar-dark-top-panel-top-links-color:              $gray-700;
$rd-navbar-dark-top-panel-top-links-color-hover:        $primary;
$rd-navbar-dark-top-panel-contact-info-color:           $gray-300;
$rd-navbar-dark-top-panel-contact-info-icon-color:      $gray-700;
$rd-navbar-dark-top-panel-mobile-background:            $black;
$rd-navbar-dark-top-panel-toggle-icon-color:            $white;


//** Navbar Default
//** Navbar Default Slogan
$rd-navbar-default-slogan-size:                   18px;
$rd-navbar-default-slogan-offset-left:            7px;
//** Navbar Default Inner
$rd-navbar-default-inner-padding:                 40px 85px 20px 85px;
//** Navbar Default Megamenu
$rd-navbar-default-megamenu-offset-top:           23px;
//** Navbar Default Cart Wrap
$rd-navbar-default-cart-wrap-offset-left:         20px;
//** Navbar Default Stuck
$rd-navbar-default-stuck-shadow:                  $shadow-area-md;
$rd-navbar-default-stuck-dropdown-offset-top:     26px;
$rd-navbar-default-stuck-inner-padding-top:       20px;
$rd-navbar-default-stuck-inner-padding-bottom:    20px;
//** Navbar Default Light Theme
$rd-navbar-default-light-inner-padding:           40px 85px 43px 85px;
$rd-navbar-default-light-background:              $white;
//** common search toggle, cart icon
$rd-navbar-default-light-toggles-color:           $gray-900;
//** common search input, search toggle, cart
$rd-navbar-default-light-inputs-color:            $gray-900;
$rd-navbar-default-light-stuck-background:        $white;
//** Navbar Default Dark Theme
//** common search input, search toggle, cart
$rd-navbar-default-dark-inputs-color:             $white;
$rd-navbar-default-dark-stuck-background:         $black;

//** RD Navbar Sidebar Toggle
$rd-navbar-sidebar-toggle-lg-toggle-width:                                315px;
$rd-navbar-sidebar-toggle-lg-toggle-padding:                              0;
$rd-navbar-sidebar-toggle-lg-toggle-offset-top:                           20px;
$rd-navbar-sidebar-toggle-lg-toggle-mobile-brand-offset-top:              45px;
$rd-navbar-sidebar-toggle-lg-toggle-form-search-offset-top:               50px;
$rd-navbar-sidebar-toggle-lg-mobile-scroll-padding:                       0 30px;
//** light sidebar toggle theme
//** common styles: navbar search toggle and navbar cart
$rd-navbar-sidebar-toggle-search-toggle-cart-color:                       $gray-900;
//** RD Navbar Sidebar Toggle Footer
$rd-navbar-sidebar-toggle-footer-list-offsets:                            50px 0 50px;
$rd-navbar-sidebar-toggle-footer-list-padding:                            0 2px;
$rd-navbar-sidebar-toggle-footer-list-inline-offsets:                     15px -2px 0;
$rd-navbar-sidebar-toggle-footer-list-definition-icon-size-size:          24px;
// dark sidebar toggle footer theme
$rd-navbar-dark-sidebar-toggle-footer-list-definition-link-hover-color:   $picton-blue;
$rd-navbar-dark-sidebar-toggle-footer-list-definition-icon-color:         $picton-blue;
// light sidebar toggle footer theme
$rd-navbar-light-sidebar-toggle-color: $gray-500;
$rd-navbar-light-sidebar-toggle-footer-list-definition-link-color:        $gray-900;
$rd-navbar-light-sidebar-toggle-footer-list-definition-link-hover-color:  $picton-blue;
$rd-navbar-light-sidebar-toggle-footer-list-definition-icon-color:        $picton-blue;
//** RD Navbar Sidebar Toggle Panel
$rd-navbar-sidebar-toggle-panel-lg-toggle-color:                          $white;
$rd-navbar-sidebar-toggle-panel-lg-toggle-size:                           30px;
$rd-navbar-sidebar-toggle-panel-lg-toggle-width:                          56px;
//** toggle icon
$rd-navbar-sidebar-toggle-panel-lg-toggle-icon-state-before:              '\f0c9';
$rd-navbar-sidebar-toggle-panel-lg-toggle-icon-state-after:               '\f69a';
$rd-navbar-sidebar-toggle-panel-lg-toggle-icon-state-family:              'FontAwesome';
// dark sidebar toggle panel theme
$rd-navbar-dark-sidebar-toggle-panel-background:                          $black;
// light sidebar toggle panel theme
$rd-navbar-light-sidebar-toggle-panel-background:                         $white;
$rd-navbar-light-sidebar-toggle-panel-color:                              $gray-900;
$rd-navbar-light-sidebar-toggle-panel-lg-toggle-color:                    $gray-900;
$rd-navbar-light-sidebar-toggle-panel-lg-toggle-width:                    48px;
$rd-navbar-light-sidebar-toggle-panel-lg-toggle-size:                     24px;

//** RD Navbar Floated
$rd-navbar-floated-offset-top:                  30px;
//** dropdown
$rd-navbar-floated-dropdown-offset-top:         29px;
//** inner
$rd-navbar-floated-inner-padding:               23px 40px;
$rd-navbar-floated-inner-border-radius:         $border-radius-small;
$rd-navbar-floated-inner-shadow:                $shadow-area-md;
//** cart
$rd-navbar-floated-cart-offset-left:            20px;
//** search
$rd-navbar-floated-search-padding-left:         40px;
//** stuck
$rd-navbar-floated-stuck-shadow:                $shadow-area-md;
$rd-navbar-floated-stuck-dropdown-offset-top:   26px;
//** RD Navbar Floated Light Theme
$rd-navbar-floated-light-inner-background:      $white;
//** common search input, search toggle, cart
$rd-navbar-floated-light-inputs-color:          $gray-900;
//** stuck
$rd-navbar-floated-light-stuck-background:      $white;
//** RD Navbar Floated Dark Theme
$rd-navbar-floated-dark-inner-background:       $black;
//** common search input, search toggle, cart
$rd-navbar-floated-dark-inputs-color:           $white;
//** stuck
$rd-navbar-floated-dark-stuck-background:       $black;

//** RD Navbar Sidebar Fixed
$rd-navbar-sidebar-fixed-panel-width:                                         64px;
$rd-navbar-sidebar-fixed-list-item-offset-top:                                20px;
$rd-navbar-sidebar-fixed-list-item-link-size:                                 28px;
$rd-navbar-sidebar-fixed-list-item-link-weight:                               400;
//** RD Navbar Sidebar Fixed Menu
//** RD Navbar Sidebar Fixed Menu Light Theme
$rd-navbar-sidebar-fixed-light-wrap-background:                               $black;
$rd-navbar-sidebar-fixed-light-wrap-color:                                    $white;
$rd-navbar-sidebar-fixed-light-list-item-active-color:                        $picton-blue;
$rd-navbar-sidebar-fixed-light-list-item-active-background:                   transparent;
//** RD Navbar Sidebar Fixed Menu Dark Theme
$rd-navbar-sidebar-fixed-dark-wrap-background:                                $white;
$rd-navbar-sidebar-fixed-dark-wrap-color:                                     $black;
$rd-navbar-sidebar-fixed-dark-list-item-active-color:                         $picton-blue;
$rd-navbar-sidebar-fixed-dark-list-item-active-background:                    transparent;
//** RD Navbar Sidebar Fixed Panel
$rd-navbar-sidebar-fixed-panel-search-toggle-icon-width:                      48px;
$rd-navbar-sidebar-fixed-panel-search-toggle-icon-size:                       28px;
$rd-navbar-sidebar-fixed-panel-search-toggle-icon-color:                      $gray-800;
$rd-navbar-sidebar-fixed-panel-search-toggle-icon:                            '\f43b';
$rd-navbar-sidebar-fixed-panel-search-toggle-icon-state-active:               '\f250';
//** RD Navbar Sidebar Fixed Panel Light Theme
$rd-navbar-sidebar-fixed-light-panel-background:                              $white;
$rd-navbar-sidebar-fixed-light-panel-shadow:                                  $shadow-area-md;
$rd-navbar-sidebar-fixed-light-panel-toggle-width:                            48px;
$rd-navbar-sidebar-fixed-light-panel-toggle-size:                             24px;
$rd-navbar-sidebar-fixed-light-panel-toggle-color:                            $white;
//** RD Navbar Sidebar Fixed Panel Dark Theme
$rd-navbar-sidebar-fixed-dark-panel-background:                               $black;
$rd-navbar-sidebar-fixed-dark-panel-toggle-color:                             $gray-800;
//** RD Navbar Sidebar Fixed Search
$rd-navbar-sidebar-fixed-search-input-size:                                   56px;
$rd-navbar-sidebar-fixed-search-input-weight:                                 700;
$rd-navbar-sidebar-fixed-search-label-size:                                   $rd-navbar-sidebar-fixed-search-input-size;
$rd-navbar-sidebar-fixed-search-label-focus-size:                             18px;
$rd-navbar-sidebar-fixed-search-submit-size:                                  14px;
$rd-navbar-sidebar-fixed-search-submit-padding:                               15px;
$rd-navbar-sidebar-fixed-search-submit-border-radius:                         $border-radius-small;
//** RD Navbar Sidebar Fixed Search Dark Theme
$rd-navbar-sidebar-fixed-dark-search-toggle-color:                            $white;
$rd-navbar-sidebar-fixed-dark-search-color:                                   $white;
$rd-navbar-sidebar-fixed-dark-search-background:                              rgba($black, .9);
$rd-navbar-sidebar-fixed-dark-search-form-background:                         $black;
$rd-navbar-sidebar-fixed-dark-search-label-color:                             $white;
$rd-navbar-sidebar-fixed-dark-search-input-color:                             $white;
$rd-navbar-sidebar-fixed-dark-search-input-background:                        $black;
$rd-navbar-sidebar-fixed-dark-search-input-focus-shadow:                      inset $shadow-area-xxs;
//** 1200
$rd-navbar-sidebar-fixed-dark-search-form-lg-background:                      transparent;
$rd-navbar-sidebar-fixed-dark-search-form-lg-border-color:                    $gray-800;
$rd-navbar-sidebar-fixed-dark-search-form-lg-icon-color:                      $gray-900;
//** quick result
$rd-navbar-sidebar-fixed-dark-search-quick-result-color:                      $picton-blue;
$rd-navbar-sidebar-fixed-dark-search-quick-result-submit-color:               $white;
$rd-navbar-sidebar-fixed-dark-search-quick-result-submit-background:          $picton-blue;
$rd-navbar-sidebar-fixed-dark-search-quick-result-submit-hover-background:    $primary;
$rd-navbar-sidebar-fixed-dark-search-quick-result-item-title-color:           $white;
$rd-navbar-sidebar-fixed-dark-search-quick-result-item-tags-color:            $gray-700;
$rd-navbar-sidebar-fixed-dark-search-quick-result-item-date-color:            $gray-700;
//** RD Navbar Sidebar Fixed Search Light Theme
$rd-navbar-sidebar-fixed-light-search-toggle-color:                           $gray-800;
$rd-navbar-sidebar-fixed-light-search-color:                                  $black;
$rd-navbar-sidebar-fixed-light-search-background:                             rgba($white, .95);
$rd-navbar-sidebar-fixed-light-search-form-background:                        $gray-800;
$rd-navbar-sidebar-fixed-light-search-input-background:                       $input-impressed-bg;
$rd-navbar-sidebar-fixed-light-search-input-focus-shadow:                     inset $shadow-area-xxs;
//** 1200
$rd-navbar-sidebar-fixed-light-search-form-lg-background:                     transparent;
$rd-navbar-sidebar-fixed-light-search-form-lg-border-color:                   $gray-800;
$rd-navbar-sidebar-fixed-light-search-form-lg-icon-color:                     $gray-500;
//** quick result
$rd-navbar-sidebar-fixed-light-search-quick-result-color:                     $picton-blue;
$rd-navbar-sidebar-fixed-light-search-quick-result-submit-color:              $white;
$rd-navbar-sidebar-fixed-light-search-quick-result-submit-background:         $primary;
$rd-navbar-sidebar-fixed-light-search-quick-result-submit-hover-background:   $picton-blue;
$rd-navbar-sidebar-fixed-light-search-quick-result-item-tags-color:           $gray-700;
$rd-navbar-sidebar-fixed-light-search-quick-result-item-date-color:           $gray-700;

//** Navbar Top Panel
//** top panel
$rd-navbar-top-panel-dropdown-offset-top:       39px;
$rd-navbar-top-panel-padding-bottom:            10px;
$rd-navbar-top-panel-margin-bottom:             39px;

//** inner
$rd-navbar-top-panel-inner-padding:             14px 0 33px;
//** brand
$rd-navbar-top-panel-brand-offset:              15px;
//** stuck
$rd-navbar-top-panel-stuck-shadow:                $shadow-area-md;
$rd-navbar-top-panel-stuck-dropdown-offset-top:   26px;

//** Navbar Top Panel Light Theme
$rd-navbar-top-panel-light-background:           $white;
//** stuck
$rd-navbar-top-panel-light-stuck-background:     $white;

//** Navbar Top Panel Dark Theme
$rd-navbar-top-panel-dark-background:            $black;
//** stuck
$rd-navbar-top-panel-dark-stuck-background:      $black;


//** Navbar Logo Center
//** top block
$rd-navbar-logo-center-top-block-padding:       35px 0;
$rd-navbar-logo-center-menu-dropdown-offset:    10px;
//** Menu
$rd-navbar-logo-center-menu-wrap-padding:       10px 0;
$rd-navbar-logo-center-menu-item-padding:       5px 11px 5px;
$rd-navbar-logo-center-menu-item-offset:        25px;
$rd-navbar-logo-center-menu-item-icon-size:     20px;

//** stuck
$rd-navbar-logo-center-stuck-shadow:                $shadow-area-md;

//** Navbar Logo Center Light Theme
$rd-navbar-logo-center-light-background:            $white;
//** Menu
$rd-navbar-logo-center-light-menu-background:       $gray-200;
$rd-navbar-logo-center-light-item-color:            $gray-900;
$rd-navbar-logo-center-light-item-color-hover:      $primary;
$rd-navbar-logo-center-light-item-color-active:     $white;
$rd-navbar-logo-center-light-item-background-active-1:     $primary;
$rd-navbar-logo-center-light-item-background-active-2:     $dollar-bill;
$rd-navbar-logo-center-light-fixed-cart-badge-background:     $dollar-bill;
$rd-navbar-logo-center-light-fixed-cart-badge-color:     $white;

//** Top block
$rd-navbar-logo-center-light-top-block-color:       $gray-800;
$rd-navbar-logo-center-light-top-block-icon-color:  $gray-500;
//** stuck
$rd-navbar-logo-center-light-stuck-background:      $white;

//** Navbar Logo Center Dark Theme
$rd-navbar-logo-center-dark-background:            $black;
//** Menu
$rd-navbar-logo-center-dark-menu-background:       $gray-900;
$rd-navbar-logo-center-dark-item-color:           $white;
$rd-navbar-logo-center-dark-item-color-hover:      $primary;
$rd-navbar-logo-center-dark-item-color-active:     $white;
$rd-navbar-logo-center-dark-item-background-active-1:     $primary;
$rd-navbar-logo-center-dark-item-background-active-2:     $dollar-bill;
$rd-navbar-logo-center-dark-fixed-cart-badge-background:     $dollar-bill;
$rd-navbar-logo-center-dark-fixed-cart-badge-color:     $white;

//** Top block
$rd-navbar-logo-center-dark-top-block-color:       $gray-700;
$rd-navbar-logo-center-dark-top-block-icon-color:  $gray-800;
//** stuck
$rd-navbar-logo-center-dark-stuck-background:      $black;

//
// RD Navbar Default Aside Left
// --------------------------------------------------

%rd-navbar-default-mobile-menu {
  .rd-navbar-nav {
    padding: 0;
    padding-right: 10px;
    margin-top: $rd-navbar-mobile-menu-list-offset-top;
    > li:last-child {
      margin-bottom: 10px;
    }
    li {
      float: none;
      a {
        @extend %rd-navbar-default-link-font;
        cursor: pointer;
        display: block;
        padding: $rd-navbar-mobile-menu-list-item-link-padding;
        vertical-align: middle;
        color: $rd-navbar-light-mobile-menu-list-item-link-color;
      }

      &.opened > a, &.active > a, &.focus > a, &:hover > a {
        color: $primary;
      }

      &.opened, &.active, &:hover {
        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-light-mobile-menu-list-item-hover-toggle-color;
          content: $rd-navbar-mobile-menu-list-item-arrow-active-content;
        }
      }
      + li {
        margin-top: $rd-navbar-mobile-menu-list-item-offset-top;
      }
    }
  }
}



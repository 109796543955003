//
// Posts
// --------------------------------------------------

// Post Common Styles
// --------------------------------------------------

.post {
  &-meta {
    .icon-xxs {
      font-size: 18px;
    }
  }

  &-title + &-meta {
    margin-top: 8px;
  }
}

// Post default
// --------------------------------------------------

.post-default {
  .post-controls {
    margin-bottom: 0;
  }

  .post-controls + .post-title {
    margin-top: 8px;
  }
}

// Post widget
// --------------------------------------------------

.widget-post {
  display: block;

  > a {
    display: inline-block;

    &:hover {
      time {
        transform: translate(10px, -1px);
      }
      h6 {
        color: $macaroni;
      }
    }
  }

  h6,
  time {
    transition: .3s;
  }
  .post-title{
    h6{
      font-size: 16px;
      letter-spacing: 0;
    }
  }
  .post-meta {
    .icon-xxs {
      font-size: 14px;
    }

    time {
      display: inline-block;
      font-size: 12px;
      transform: translate(0, -1px);
    }
  }

  & + .widget-post {
    margin-top: 16px;
  }
}
//
// Input group custom
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.input-group-addon {
  vertical-align: middle;
  transition: .3s;
  color: $input-group-addon-color;

  > .input-group-icon {
    position: relative;
    display: inline-block;
  }
}

.input-group-addon-inverse{
  background: $white;
  border-color: $gray-500;
  color: $gray-800;
}

.input-group {
  .form-input{
    color: $input-color;
    background: $white;
  }

  > .input-group-addon > .input-group-icon {
    font-size: 28px;
  }
}

.input-group.input-group-sm {
  .form-control {
    height: $input-height-sm;
  }

  > .input-group-addon > .input-group-icon {
    font-size: 19px;
    line-height: 1;

    &.mdi {
      top: 2px;
    }
  }

  > .input-group-btn > .btn {
    font-size: $btn-font-size-small;
  }
}

.input-group.input-group-lg {
  .form-control {
    height: $input-height-lg;
  }

  > .input-group-addon > .input-group-icon {
    font-size: 32px;
  }
}

.input-group .form-input:not(:first-child):not(:last-child){
  &.form-input-last-child{
    border-top-right-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;
  }
}

// Button input groups
// --------------------------------------------------

.input-group-btn {

  // Negative margin for spacing, position for bringing hovered/focused/actived
  // element above the siblings.
  > .btn {
    + .btn {
      margin-left: 0;
    }
  }
}

    /*
    Flaticon icon font: Flaticon
    Creation date: 19/02/2016 14:46
    */

    @font-face {
  font-family: "Flaticon";
  src: url("/fonts/Flaticon.eot");
  src: url("/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("/fonts/Flaticon.woff") format("woff"),
       url("/fonts/Flaticon.ttf") format("truetype"),
       url("/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-add-circular-outlined-button:before { content: "\f100"; }
.flaticon-add-cross-outlined-symbol:before { content: "\f101"; }
.flaticon-add-square-outlined-interface-button:before { content: "\f102"; }
.flaticon-airplane-rotated-diagonal-transport-outlined-symbol:before { content: "\f103"; }
.flaticon-alarm-clock-symbol:before { content: "\f104"; }
.flaticon-arrow-entering-into-square:before { content: "\f105"; }
.flaticon-attach-interface-symbol-of-rotated-paperclip:before { content: "\f106"; }
.flaticon-attachment:before { content: "\f107"; }
.flaticon-audio-tool-in-silence:before { content: "\f108"; }
.flaticon-back-left-arrow-circular-button-outline:before { content: "\f109"; }
.flaticon-back-left-arrow-square-button-outline:before { content: "\f10a"; }
.flaticon-big-map-placeholder-outlined-symbol-of-interface:before { content: "\f10b"; }
.flaticon-book-outline:before { content: "\f10c"; }
.flaticon-bookmark-outlined-interface-symbol:before { content: "\f10d"; }
.flaticon-bottle-gross-outlined-symbol:before { content: "\f10e"; }
.flaticon-button-of-nine-outlined-circles:before { content: "\f10f"; }
.flaticon-buttons:before { content: "\f110"; }
.flaticon-cellphone:before { content: "\f111"; }
.flaticon-cellular-phone-outline:before { content: "\f112"; }
.flaticon-center-text-interface-symbol-of-lines:before { content: "\f113"; }
.flaticon-chat-comment-oval-speech-bubble-with-text-lines:before { content: "\f114"; }
.flaticon-chat-oval-speech-bubbles-symbol:before { content: "\f115"; }
.flaticon-checkmark-outline:before { content: "\f116"; }
.flaticon-checkmark-square-button-outline:before { content: "\f117"; }
.flaticon-checkmark-verify-interface-symbol-button:before { content: "\f118"; }
.flaticon-circular-arrow-counterclockwise-rotating-symbol:before { content: "\f119"; }
.flaticon-circular-graphic-outline:before { content: "\f11a"; }
.flaticon-circular-outlined-badge-with-ribbon-recognition-prize-symbol:before { content: "\f11b"; }
.flaticon-circular-target-shooting-interface-symbol:before { content: "\f11c"; }
.flaticon-clipboard-square-symbol:before { content: "\f11d"; }
.flaticon-close-circular-button-symbol:before { content: "\f11e"; }
.flaticon-cloud-outlined-shape:before { content: "\f11f"; }
.flaticon-cloud-with-hail-falling:before { content: "\f120"; }
.flaticon-cocktail-glass-outline:before { content: "\f121"; }
.flaticon-code-signs:before { content: "\f122"; }
.flaticon-collapse-two-arrows-diagonal-symbol:before { content: "\f123"; }
.flaticon-comment-oval-outlined-balloon:before { content: "\f124"; }
.flaticon-compass-orientation-symbol:before { content: "\f125"; }
.flaticon-contract-two-arrows-symbol-pointing-the-center-of-square-button:before { content: "\f126"; }
.flaticon-copy-two-paper-sheets-interface-symbol:before { content: "\f127"; }
.flaticon-crescent-moon-phase-outlined-shape:before { content: "\f128"; }
.flaticon-cropping-interface-tool-symbol:before { content: "\f129"; }
.flaticon-cross-rounded-outline:before { content: "\f12a"; }
.flaticon-cross-square-button:before { content: "\f12b"; }
.flaticon-cube-of-notes-stack:before { content: "\f12c"; }
.flaticon-double-arrow-horizontal-symbol:before { content: "\f12d"; }
.flaticon-double-arrow-vertical-symbol:before { content: "\f12e"; }
.flaticon-double-right-arrows-angles:before { content: "\f12f"; }
.flaticon-down-arrow:before { content: "\f130"; }
.flaticon-down-arrow-square-button:before { content: "\f131"; }
.flaticon-down-arrow-square-outlined-button:before { content: "\f132"; }
.flaticon-down-arrow-to-a-square:before { content: "\f133"; }
.flaticon-down-circular-button:before { content: "\f134"; }
.flaticon-download-circular-button:before { content: "\f135"; }
.flaticon-download-down-arrow-symbol:before { content: "\f136"; }
.flaticon-download-from-internet-cloud:before { content: "\f137"; }
.flaticon-download-square-button:before { content: "\f138"; }
.flaticon-download-square-outlined-interface-button:before { content: "\f139"; }
.flaticon-electrical-storm-weather-symbol:before { content: "\f13a"; }
.flaticon-electronic-game-machine-outline:before { content: "\f13b"; }
.flaticon-email-closed-outlined-back-envelope-interface-symbol:before { content: "\f13c"; }
.flaticon-email-square-outlined-interface-symbol-of-envelope-back:before { content: "\f13d"; }
.flaticon-equalization:before { content: "\f13e"; }
.flaticon-equalizer-interface-square-button-symbol:before { content: "\f13f"; }
.flaticon-event-weekly-calendar-symbol:before { content: "\f140"; }
.flaticon-expand-square-interface-button-of-two-arrows:before { content: "\f141"; }
.flaticon-expanding-two-opposite-arrows-diagonal-symbol-of-interface:before { content: "\f142"; }
.flaticon-fast-forward-double-arrow-outline:before { content: "\f143"; }
.flaticon-favourites-star-outline-interface-symbol:before { content: "\f144"; }
.flaticon-file-rounded-empty-sheet:before { content: "\f145"; }
.flaticon-file-rounded-outlined-symbol:before { content: "\f146"; }
.flaticon-film-strip-couple-of-photograms:before { content: "\f147"; }
.flaticon-flag-outline:before { content: "\f148"; }
.flaticon-fog-at-night-weather-symbol:before { content: "\f149"; }
.flaticon-foggy-day-fog-at-sunset-or-sunrise:before { content: "\f14a"; }
.flaticon-folder-outline:before { content: "\f14b"; }
.flaticon-fork:before { content: "\f14c"; }
.flaticon-four-rounded-squares-button:before { content: "\f14d"; }
.flaticon-full-screen-interface-symbol-of-four-arrows-in-square-button:before { content: "\f14e"; }
.flaticon-games-machine-outline:before { content: "\f14f"; }
.flaticon-gear-outlined-symbol:before { content: "\f150"; }
.flaticon-giftbox-outline:before { content: "\f151"; }
.flaticon-graphical-business-presentation-on-a-screen:before { content: "\f152"; }
.flaticon-headphones-audio-symbol:before { content: "\f153"; }
.flaticon-hot-interface-symbol-of-fire-flames-outline:before { content: "\f154"; }
.flaticon-images-square-outlined-interface-button-symbol:before { content: "\f155"; }
.flaticon-ink-interface-symbol-of-drop-outline:before { content: "\f156"; }
.flaticon-label-outline:before { content: "\f157"; }
.flaticon-left-alignment:before { content: "\f158"; }
.flaticon-left-arrow-curve-outline:before { content: "\f159"; }
.flaticon-left-arrow-in-circle-outline:before { content: "\f15a"; }
.flaticon-left-arrow-line-symbol:before { content: "\f15b"; }
.flaticon-left-arrow-rounded-outlined-button-interface-symbol:before { content: "\f15c"; }
.flaticon-left-arrow-signal-outline:before { content: "\f15d"; }
.flaticon-left-arrow-square-outlined-button:before { content: "\f15e"; }
.flaticon-left-arrow-with-a-cross:before { content: "\f15f"; }
.flaticon-like-heart-outline-symbol:before { content: "\f160"; }
.flaticon-link-button:before { content: "\f161"; }
.flaticon-list:before { content: "\f162"; }
.flaticon-list-interface-symbol:before { content: "\f163"; }
.flaticon-lock-circular-padlock-outline-tool-symbol:before { content: "\f164"; }
.flaticon-login-arrow-symbol-entering-back-into-a-square:before { content: "\f165"; }
.flaticon-login-square-arrow-button-outline:before { content: "\f166"; }
.flaticon-map-folded-outlined-paper:before { content: "\f167"; }
.flaticon-megaphone-outline-of-amplification-tool:before { content: "\f168"; }
.flaticon-men:before { content: "\f169"; }
.flaticon-menu-square-button:before { content: "\f16a"; }
.flaticon-menu-three-outlined-rounded-lines-symbol:before { content: "\f16b"; }
.flaticon-microphone:before { content: "\f16c"; }
.flaticon-microphone-outlined-tool:before { content: "\f16d"; }
.flaticon-minus-circular-button:before { content: "\f16e"; }
.flaticon-minus-in-zoom-symbol:before { content: "\f16f"; }
.flaticon-minus-outline:before { content: "\f170"; }
.flaticon-minus-square-outlined-button:before { content: "\f171"; }
.flaticon-monitor-outline:before { content: "\f172"; }
.flaticon-music-note:before { content: "\f173"; }
.flaticon-music-rectangular-interface-button-outline:before { content: "\f174"; }
.flaticon-music-theme:before { content: "\f175"; }
.flaticon-musical-note:before { content: "\f176"; }
.flaticon-musical-note-outlined-symbol:before { content: "\f177"; }
.flaticon-mute-voice:before { content: "\f178"; }
.flaticon-new-email-envelope-frontal-view:before { content: "\f179"; }
.flaticon-nine-oclock-on-circular-clock:before { content: "\f17a"; }
.flaticon-note-outlined-symbol:before { content: "\f17b"; }
.flaticon-notebook-rectangular-symbol-with-spring:before { content: "\f17c"; }
.flaticon-notification-bell-outline-interface-symbol:before { content: "\f17d"; }
.flaticon-notification-bell-outline-interface-symbol-1:before { content: "\f17e"; }
.flaticon-opened-email-outlined-envelope-back-interface-symbol:before { content: "\f17f"; }
.flaticon-oval-empty-outlined-speech-bubble:before { content: "\f180"; }
.flaticon-paintbrush-outline:before { content: "\f181"; }
.flaticon-paper-airplane-outline:before { content: "\f182"; }
.flaticon-pause-outlined-big-symbol:before { content: "\f183"; }
.flaticon-pencil-big-outlined-vertical-tool-symbol:before { content: "\f184"; }
.flaticon-phone-auricular-outline:before { content: "\f185"; }
.flaticon-photo-camera-outline:before { content: "\f186"; }
.flaticon-pin:before { content: "\f187"; }
.flaticon-planetary-grid-symbol:before { content: "\f188"; }
.flaticon-play-triangle-outline:before { content: "\f189"; }
.flaticon-portfolio-outline:before { content: "\f18a"; }
.flaticon-print-interface-button-symbol-of-printer-outline:before { content: "\f18b"; }
.flaticon-radio:before { content: "\f18c"; }
.flaticon-rain-weather-cloud-outline-symbol-with-raindrops-lines:before { content: "\f18d"; }
.flaticon-rectangular-outlined-speech-bubble-symbol:before { content: "\f18e"; }
.flaticon-recycling-bin:before { content: "\f18f"; }
.flaticon-rewind-double-arrow-outline-multimedia-button-symbol:before { content: "\f190"; }
.flaticon-right-arrow-angle:before { content: "\f191"; }
.flaticon-right-arrow-circular-button-outline:before { content: "\f192"; }
.flaticon-right-arrow-circular-outlined-button:before { content: "\f193"; }
.flaticon-right-arrow-on-a-pole-outlined-orientation-signal-symbol:before { content: "\f194"; }
.flaticon-right-arrow-outline:before { content: "\f195"; }
.flaticon-right-arrow-square-button-symbol:before { content: "\f196"; }
.flaticon-right-arrow-square-outlined-button:before { content: "\f197"; }
.flaticon-right-arrow-symbol-in-a-square-outline:before { content: "\f198"; }
.flaticon-rounded-rectangular-tool-shape:before { content: "\f199"; }
.flaticon-sand-clock:before { content: "\f19a"; }
.flaticon-save-button-interface-symbol-of-outlined-diskette:before { content: "\f19b"; }
.flaticon-search-interface-symbol:before { content: "\f19c"; }
.flaticon-search-oval-magnification-tool-or-spoon-interface-symbol:before { content: "\f19d"; }
.flaticon-share-social-interface-button:before { content: "\f19e"; }
.flaticon-shopping-bag:before { content: "\f19f"; }
.flaticon-shopping-basket-e-commerce-symbol:before { content: "\f1a0"; }
.flaticon-shopping-cart-outline:before { content: "\f1a1"; }
.flaticon-shopping-cart-outline-1:before { content: "\f1a2"; }
.flaticon-shuffle:before { content: "\f1a3"; }
.flaticon-sort-ascending:before { content: "\f1a4"; }
.flaticon-sort-descending:before { content: "\f1a5"; }
.flaticon-speaker-musical-amplification-outlined-frontal-tool:before { content: "\f1a6"; }
.flaticon-speaker-outline:before { content: "\f1a7"; }
.flaticon-speaker-outline-1:before { content: "\f1a8"; }
.flaticon-speaker-outline-audio-interface-symbol:before { content: "\f1a9"; }
.flaticon-speaker-outlined-square-frontal-tool-audio-tool-symbol:before { content: "\f1aa"; }
.flaticon-speech-bubble-oval-symbol-with-three-dots:before { content: "\f1ab"; }
.flaticon-square-outline:before { content: "\f1ac"; }
.flaticon-square-shape-design-interface-tool-symbol:before { content: "\f1ad"; }
.flaticon-square-targeting-interface-symbol:before { content: "\f1ae"; }
.flaticon-store-commercial-symbol-outline:before { content: "\f1af"; }
.flaticon-sun-day-weather-symbol:before { content: "\f1b0"; }
.flaticon-sunrise-or-sunset:before { content: "\f1b1"; }
.flaticon-switch-outlined-symbol:before { content: "\f1b2"; }
.flaticon-switch-outlined-tool-symbol:before { content: "\f1b3"; }
.flaticon-tag-outline:before { content: "\f1b4"; }
.flaticon-television-outline:before { content: "\f1b5"; }
.flaticon-text-alignment-option-button-interface-symbol:before { content: "\f1b6"; }
.flaticon-text-paper-sheet-symbol:before { content: "\f1b7"; }
.flaticon-three-vertical-outlined-bars-symbol:before { content: "\f1b8"; }
.flaticon-timer-timing-tool:before { content: "\f1b9"; }
.flaticon-tool-outline-of-hotel-reception-or-covered-food-tray:before { content: "\f1ba"; }
.flaticon-triangle-equilateral-outline-shape-on-horizontal-line:before { content: "\f1bb"; }
.flaticon-trophy-outline:before { content: "\f1bc"; }
.flaticon-two-arrows:before { content: "\f1bd"; }
.flaticon-two-circular-arrows:before { content: "\f1be"; }
.flaticon-two-circular-counterclockwise-rotating-arrows-symbol:before { content: "\f1bf"; }
.flaticon-two-drops-sizes-outlined-shapes:before { content: "\f1c0"; }
.flaticon-two-rounded-equal-squares-outlines-symbol:before { content: "\f1c1"; }
.flaticon-unlocked-circular-padlock-outline:before { content: "\f1c2"; }
.flaticon-up-arrow-angle:before { content: "\f1c3"; }
.flaticon-up-arrow-circular-button:before { content: "\f1c4"; }
.flaticon-up-arrow-in-square-interface-button:before { content: "\f1c5"; }
.flaticon-up-arrow-into-square:before { content: "\f1c6"; }
.flaticon-up-arrow-or-flag-shape-big-gross-outlined-symbol:before { content: "\f1c7"; }
.flaticon-up-arrow-square-button:before { content: "\f1c8"; }
.flaticon-up-arrow-square-button-outline:before { content: "\f1c9"; }
.flaticon-up-arrow-with-house-shape-outlined-symbol:before { content: "\f1ca"; }
.flaticon-up-circular-interface-button:before { content: "\f1cb"; }
.flaticon-up-square-button-outline:before { content: "\f1cc"; }
.flaticon-upload:before { content: "\f1cd"; }
.flaticon-upload-to-the-cloud:before { content: "\f1ce"; }
.flaticon-upper-right-arrow-square-button-outlined-symbol:before { content: "\f1cf"; }
.flaticon-user-info-interface-symbol-with-text-lines-at-right-side:before { content: "\f1d0"; }
.flaticon-user-outline-male-symbol-of-interface:before { content: "\f1d1"; }
.flaticon-video-camera-outline:before { content: "\f1d2"; }
.flaticon-wallet-outline:before { content: "\f1d3"; }
.flaticon-weather-interface-symbol-of-a-semicircle-on-three-lines-perspective:before { content: "\f1d4"; }
.flaticon-weekly-calendar:before { content: "\f1d5"; }
.flaticon-weekly-calendar-outline-event-interface-symbol:before { content: "\f1d6"; }
.flaticon-wind-socket-outlined-symbol:before { content: "\f1d7"; }
.flaticon-window-outline:before { content: "\f1d8"; }
.flaticon-winds-lines-weather-symbol:before { content: "\f1d9"; }
.flaticon-wrench-outline:before { content: "\f1da"; }
.flaticon-zoom-increasing-symbol:before { content: "\f1db"; }
    
    $font-Flaticon-add-circular-outlined-button: "\f100";
    $font-Flaticon-add-cross-outlined-symbol: "\f101";
    $font-Flaticon-add-square-outlined-interface-button: "\f102";
    $font-Flaticon-airplane-rotated-diagonal-transport-outlined-symbol: "\f103";
    $font-Flaticon-alarm-clock-symbol: "\f104";
    $font-Flaticon-arrow-entering-into-square: "\f105";
    $font-Flaticon-attach-interface-symbol-of-rotated-paperclip: "\f106";
    $font-Flaticon-attachment: "\f107";
    $font-Flaticon-audio-tool-in-silence: "\f108";
    $font-Flaticon-back-left-arrow-circular-button-outline: "\f109";
    $font-Flaticon-back-left-arrow-square-button-outline: "\f10a";
    $font-Flaticon-big-map-placeholder-outlined-symbol-of-interface: "\f10b";
    $font-Flaticon-book-outline: "\f10c";
    $font-Flaticon-bookmark-outlined-interface-symbol: "\f10d";
    $font-Flaticon-bottle-gross-outlined-symbol: "\f10e";
    $font-Flaticon-button-of-nine-outlined-circles: "\f10f";
    $font-Flaticon-buttons: "\f110";
    $font-Flaticon-cellphone: "\f111";
    $font-Flaticon-cellular-phone-outline: "\f112";
    $font-Flaticon-center-text-interface-symbol-of-lines: "\f113";
    $font-Flaticon-chat-comment-oval-speech-bubble-with-text-lines: "\f114";
    $font-Flaticon-chat-oval-speech-bubbles-symbol: "\f115";
    $font-Flaticon-checkmark-outline: "\f116";
    $font-Flaticon-checkmark-square-button-outline: "\f117";
    $font-Flaticon-checkmark-verify-interface-symbol-button: "\f118";
    $font-Flaticon-circular-arrow-counterclockwise-rotating-symbol: "\f119";
    $font-Flaticon-circular-graphic-outline: "\f11a";
    $font-Flaticon-circular-outlined-badge-with-ribbon-recognition-prize-symbol: "\f11b";
    $font-Flaticon-circular-target-shooting-interface-symbol: "\f11c";
    $font-Flaticon-clipboard-square-symbol: "\f11d";
    $font-Flaticon-close-circular-button-symbol: "\f11e";
    $font-Flaticon-cloud-outlined-shape: "\f11f";
    $font-Flaticon-cloud-with-hail-falling: "\f120";
    $font-Flaticon-cocktail-glass-outline: "\f121";
    $font-Flaticon-code-signs: "\f122";
    $font-Flaticon-collapse-two-arrows-diagonal-symbol: "\f123";
    $font-Flaticon-comment-oval-outlined-balloon: "\f124";
    $font-Flaticon-compass-orientation-symbol: "\f125";
    $font-Flaticon-contract-two-arrows-symbol-pointing-the-center-of-square-button: "\f126";
    $font-Flaticon-copy-two-paper-sheets-interface-symbol: "\f127";
    $font-Flaticon-crescent-moon-phase-outlined-shape: "\f128";
    $font-Flaticon-cropping-interface-tool-symbol: "\f129";
    $font-Flaticon-cross-rounded-outline: "\f12a";
    $font-Flaticon-cross-square-button: "\f12b";
    $font-Flaticon-cube-of-notes-stack: "\f12c";
    $font-Flaticon-double-arrow-horizontal-symbol: "\f12d";
    $font-Flaticon-double-arrow-vertical-symbol: "\f12e";
    $font-Flaticon-double-right-arrows-angles: "\f12f";
    $font-Flaticon-down-arrow: "\f130";
    $font-Flaticon-down-arrow-square-button: "\f131";
    $font-Flaticon-down-arrow-square-outlined-button: "\f132";
    $font-Flaticon-down-arrow-to-a-square: "\f133";
    $font-Flaticon-down-circular-button: "\f134";
    $font-Flaticon-download-circular-button: "\f135";
    $font-Flaticon-download-down-arrow-symbol: "\f136";
    $font-Flaticon-download-from-internet-cloud: "\f137";
    $font-Flaticon-download-square-button: "\f138";
    $font-Flaticon-download-square-outlined-interface-button: "\f139";
    $font-Flaticon-electrical-storm-weather-symbol: "\f13a";
    $font-Flaticon-electronic-game-machine-outline: "\f13b";
    $font-Flaticon-email-closed-outlined-back-envelope-interface-symbol: "\f13c";
    $font-Flaticon-email-square-outlined-interface-symbol-of-envelope-back: "\f13d";
    $font-Flaticon-equalization: "\f13e";
    $font-Flaticon-equalizer-interface-square-button-symbol: "\f13f";
    $font-Flaticon-event-weekly-calendar-symbol: "\f140";
    $font-Flaticon-expand-square-interface-button-of-two-arrows: "\f141";
    $font-Flaticon-expanding-two-opposite-arrows-diagonal-symbol-of-interface: "\f142";
    $font-Flaticon-fast-forward-double-arrow-outline: "\f143";
    $font-Flaticon-favourites-star-outline-interface-symbol: "\f144";
    $font-Flaticon-file-rounded-empty-sheet: "\f145";
    $font-Flaticon-file-rounded-outlined-symbol: "\f146";
    $font-Flaticon-film-strip-couple-of-photograms: "\f147";
    $font-Flaticon-flag-outline: "\f148";
    $font-Flaticon-fog-at-night-weather-symbol: "\f149";
    $font-Flaticon-foggy-day-fog-at-sunset-or-sunrise: "\f14a";
    $font-Flaticon-folder-outline: "\f14b";
    $font-Flaticon-fork: "\f14c";
    $font-Flaticon-four-rounded-squares-button: "\f14d";
    $font-Flaticon-full-screen-interface-symbol-of-four-arrows-in-square-button: "\f14e";
    $font-Flaticon-games-machine-outline: "\f14f";
    $font-Flaticon-gear-outlined-symbol: "\f150";
    $font-Flaticon-giftbox-outline: "\f151";
    $font-Flaticon-graphical-business-presentation-on-a-screen: "\f152";
    $font-Flaticon-headphones-audio-symbol: "\f153";
    $font-Flaticon-hot-interface-symbol-of-fire-flames-outline: "\f154";
    $font-Flaticon-images-square-outlined-interface-button-symbol: "\f155";
    $font-Flaticon-ink-interface-symbol-of-drop-outline: "\f156";
    $font-Flaticon-label-outline: "\f157";
    $font-Flaticon-left-alignment: "\f158";
    $font-Flaticon-left-arrow-curve-outline: "\f159";
    $font-Flaticon-left-arrow-in-circle-outline: "\f15a";
    $font-Flaticon-left-arrow-line-symbol: "\f15b";
    $font-Flaticon-left-arrow-rounded-outlined-button-interface-symbol: "\f15c";
    $font-Flaticon-left-arrow-signal-outline: "\f15d";
    $font-Flaticon-left-arrow-square-outlined-button: "\f15e";
    $font-Flaticon-left-arrow-with-a-cross: "\f15f";
    $font-Flaticon-like-heart-outline-symbol: "\f160";
    $font-Flaticon-link-button: "\f161";
    $font-Flaticon-list: "\f162";
    $font-Flaticon-list-interface-symbol: "\f163";
    $font-Flaticon-lock-circular-padlock-outline-tool-symbol: "\f164";
    $font-Flaticon-login-arrow-symbol-entering-back-into-a-square: "\f165";
    $font-Flaticon-login-square-arrow-button-outline: "\f166";
    $font-Flaticon-map-folded-outlined-paper: "\f167";
    $font-Flaticon-megaphone-outline-of-amplification-tool: "\f168";
    $font-Flaticon-men: "\f169";
    $font-Flaticon-menu-square-button: "\f16a";
    $font-Flaticon-menu-three-outlined-rounded-lines-symbol: "\f16b";
    $font-Flaticon-microphone: "\f16c";
    $font-Flaticon-microphone-outlined-tool: "\f16d";
    $font-Flaticon-minus-circular-button: "\f16e";
    $font-Flaticon-minus-in-zoom-symbol: "\f16f";
    $font-Flaticon-minus-outline: "\f170";
    $font-Flaticon-minus-square-outlined-button: "\f171";
    $font-Flaticon-monitor-outline: "\f172";
    $font-Flaticon-music-note: "\f173";
    $font-Flaticon-music-rectangular-interface-button-outline: "\f174";
    $font-Flaticon-music-theme: "\f175";
    $font-Flaticon-musical-note: "\f176";
    $font-Flaticon-musical-note-outlined-symbol: "\f177";
    $font-Flaticon-mute-voice: "\f178";
    $font-Flaticon-new-email-envelope-frontal-view: "\f179";
    $font-Flaticon-nine-oclock-on-circular-clock: "\f17a";
    $font-Flaticon-note-outlined-symbol: "\f17b";
    $font-Flaticon-notebook-rectangular-symbol-with-spring: "\f17c";
    $font-Flaticon-notification-bell-outline-interface-symbol: "\f17d";
    $font-Flaticon-notification-bell-outline-interface-symbol-1: "\f17e";
    $font-Flaticon-opened-email-outlined-envelope-back-interface-symbol: "\f17f";
    $font-Flaticon-oval-empty-outlined-speech-bubble: "\f180";
    $font-Flaticon-paintbrush-outline: "\f181";
    $font-Flaticon-paper-airplane-outline: "\f182";
    $font-Flaticon-pause-outlined-big-symbol: "\f183";
    $font-Flaticon-pencil-big-outlined-vertical-tool-symbol: "\f184";
    $font-Flaticon-phone-auricular-outline: "\f185";
    $font-Flaticon-photo-camera-outline: "\f186";
    $font-Flaticon-pin: "\f187";
    $font-Flaticon-planetary-grid-symbol: "\f188";
    $font-Flaticon-play-triangle-outline: "\f189";
    $font-Flaticon-portfolio-outline: "\f18a";
    $font-Flaticon-print-interface-button-symbol-of-printer-outline: "\f18b";
    $font-Flaticon-radio: "\f18c";
    $font-Flaticon-rain-weather-cloud-outline-symbol-with-raindrops-lines: "\f18d";
    $font-Flaticon-rectangular-outlined-speech-bubble-symbol: "\f18e";
    $font-Flaticon-recycling-bin: "\f18f";
    $font-Flaticon-rewind-double-arrow-outline-multimedia-button-symbol: "\f190";
    $font-Flaticon-right-arrow-angle: "\f191";
    $font-Flaticon-right-arrow-circular-button-outline: "\f192";
    $font-Flaticon-right-arrow-circular-outlined-button: "\f193";
    $font-Flaticon-right-arrow-on-a-pole-outlined-orientation-signal-symbol: "\f194";
    $font-Flaticon-right-arrow-outline: "\f195";
    $font-Flaticon-right-arrow-square-button-symbol: "\f196";
    $font-Flaticon-right-arrow-square-outlined-button: "\f197";
    $font-Flaticon-right-arrow-symbol-in-a-square-outline: "\f198";
    $font-Flaticon-rounded-rectangular-tool-shape: "\f199";
    $font-Flaticon-sand-clock: "\f19a";
    $font-Flaticon-save-button-interface-symbol-of-outlined-diskette: "\f19b";
    $font-Flaticon-search-interface-symbol: "\f19c";
    $font-Flaticon-search-oval-magnification-tool-or-spoon-interface-symbol: "\f19d";
    $font-Flaticon-share-social-interface-button: "\f19e";
    $font-Flaticon-shopping-bag: "\f19f";
    $font-Flaticon-shopping-basket-e-commerce-symbol: "\f1a0";
    $font-Flaticon-shopping-cart-outline: "\f1a1";
    $font-Flaticon-shopping-cart-outline-1: "\f1a2";
    $font-Flaticon-shuffle: "\f1a3";
    $font-Flaticon-sort-ascending: "\f1a4";
    $font-Flaticon-sort-descending: "\f1a5";
    $font-Flaticon-speaker-musical-amplification-outlined-frontal-tool: "\f1a6";
    $font-Flaticon-speaker-outline: "\f1a7";
    $font-Flaticon-speaker-outline-1: "\f1a8";
    $font-Flaticon-speaker-outline-audio-interface-symbol: "\f1a9";
    $font-Flaticon-speaker-outlined-square-frontal-tool-audio-tool-symbol: "\f1aa";
    $font-Flaticon-speech-bubble-oval-symbol-with-three-dots: "\f1ab";
    $font-Flaticon-square-outline: "\f1ac";
    $font-Flaticon-square-shape-design-interface-tool-symbol: "\f1ad";
    $font-Flaticon-square-targeting-interface-symbol: "\f1ae";
    $font-Flaticon-store-commercial-symbol-outline: "\f1af";
    $font-Flaticon-sun-day-weather-symbol: "\f1b0";
    $font-Flaticon-sunrise-or-sunset: "\f1b1";
    $font-Flaticon-switch-outlined-symbol: "\f1b2";
    $font-Flaticon-switch-outlined-tool-symbol: "\f1b3";
    $font-Flaticon-tag-outline: "\f1b4";
    $font-Flaticon-television-outline: "\f1b5";
    $font-Flaticon-text-alignment-option-button-interface-symbol: "\f1b6";
    $font-Flaticon-text-paper-sheet-symbol: "\f1b7";
    $font-Flaticon-three-vertical-outlined-bars-symbol: "\f1b8";
    $font-Flaticon-timer-timing-tool: "\f1b9";
    $font-Flaticon-tool-outline-of-hotel-reception-or-covered-food-tray: "\f1ba";
    $font-Flaticon-triangle-equilateral-outline-shape-on-horizontal-line: "\f1bb";
    $font-Flaticon-trophy-outline: "\f1bc";
    $font-Flaticon-two-arrows: "\f1bd";
    $font-Flaticon-two-circular-arrows: "\f1be";
    $font-Flaticon-two-circular-counterclockwise-rotating-arrows-symbol: "\f1bf";
    $font-Flaticon-two-drops-sizes-outlined-shapes: "\f1c0";
    $font-Flaticon-two-rounded-equal-squares-outlines-symbol: "\f1c1";
    $font-Flaticon-unlocked-circular-padlock-outline: "\f1c2";
    $font-Flaticon-up-arrow-angle: "\f1c3";
    $font-Flaticon-up-arrow-circular-button: "\f1c4";
    $font-Flaticon-up-arrow-in-square-interface-button: "\f1c5";
    $font-Flaticon-up-arrow-into-square: "\f1c6";
    $font-Flaticon-up-arrow-or-flag-shape-big-gross-outlined-symbol: "\f1c7";
    $font-Flaticon-up-arrow-square-button: "\f1c8";
    $font-Flaticon-up-arrow-square-button-outline: "\f1c9";
    $font-Flaticon-up-arrow-with-house-shape-outlined-symbol: "\f1ca";
    $font-Flaticon-up-circular-interface-button: "\f1cb";
    $font-Flaticon-up-square-button-outline: "\f1cc";
    $font-Flaticon-upload: "\f1cd";
    $font-Flaticon-upload-to-the-cloud: "\f1ce";
    $font-Flaticon-upper-right-arrow-square-button-outlined-symbol: "\f1cf";
    $font-Flaticon-user-info-interface-symbol-with-text-lines-at-right-side: "\f1d0";
    $font-Flaticon-user-outline-male-symbol-of-interface: "\f1d1";
    $font-Flaticon-video-camera-outline: "\f1d2";
    $font-Flaticon-wallet-outline: "\f1d3";
    $font-Flaticon-weather-interface-symbol-of-a-semicircle-on-three-lines-perspective: "\f1d4";
    $font-Flaticon-weekly-calendar: "\f1d5";
    $font-Flaticon-weekly-calendar-outline-event-interface-symbol: "\f1d6";
    $font-Flaticon-wind-socket-outlined-symbol: "\f1d7";
    $font-Flaticon-window-outline: "\f1d8";
    $font-Flaticon-winds-lines-weather-symbol: "\f1d9";
    $font-Flaticon-wrench-outline: "\f1da";
    $font-Flaticon-zoom-increasing-symbol: "\f1db";

//
// RD Navbar Sidebar Toggle
// --------------------------------------------------

.rd-navbar-humburger-menu {
  &.rd-navbar-fixed {
    @extend %rd-navbar-default-mobile-menu;
    @extend %rd-navbar-default-aside-left;
    @extend %rd-navbar-sidebar-toggle-panel;
    @extend %rd-navbar-sidebar-toggle-footer;

    .rd-navbar-nav-wrap {
      height: 100%;
      overflow: auto;
      bottom: auto;
    }
    .rd-navbar-mobile-scroll {
      min-height: 100vh;
      padding-left: 20px;
    }
    .rd-navbar-panel{
      text-align: center;
    }
    .rd-navbar-brand{
      display: inline-block;
      height: 56px;
      line-height: 52px;
      margin-left: 30px;
      @include media-breakpoint-up(sm) {
        margin-left: 0;
      }
    }

    @include media-breakpoint-up(xxl) {
      .rd-navbar-mobile-scroll {
        padding-left: 0;
        display: none;
      }
      .rd-navbar-brand{
        display: none;
      }
      .rd-navbar-nav-wrap {
        display: none;
        .rd-navbar-nav {
          position: absolute;
          top: 50%;
          width: 100%;
          transform: translateY(-50%);
          li {
            a {
              padding: 0;
              font-weight: 400;
              text-transform: none;
              position: relative;
              color: rgba(51, 51, 51, .34);
              font-size: 10px;
              &:after {
                content: "";
                position: absolute;
                right: 55px;
                background: rgba(0, 0, 0, .1);
                top: 50%;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                transform: translateY(-50%);
                border: 4px solid transparent;
                transition: 0.35s all ease;
              }
            }
            & + li {
              margin-top: 15px;
            }
            &.active, &:hover {
              a {
                color: rgba(51, 51, 51, 1);

                font-size: 12px;
                &:after {
                  right: 51px;
                  width: 14px;
                  height: 14px;
                  background: rgba(0, 0, 0, 1);
                  border-color: #ddd;
                }
              }
            }
          }
          &.dark-section{
            li {
              a {
                color: rgba(255, 255, 255, .34);
                &:after {
                  background: rgba(255, 255, 255, .1);
                }
              }
              &.active, &:hover {
                a {
                  color: rgba(255, 255, 255, 1);
                  &:after {
                    background: rgba(255, 255, 255, 1);
                    border-color: #e5e5e5;
                  }
                }
              }
            }
          }
        }
      }
      .rd-navbar-panel {
        position: static;
      }
      &, .rd-navbar-panel {
        height: 0;
      }
      .rd-navbar-toggle {
        display: none;
      }
    }
  }
}
//
// RD Navbar Sidebar Toggle Panel
// --------------------------------------------------

%rd-navbar-sidebar-toggle-panel{
  .rd-navbar-panel{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 16;
  }
  .rd-navbar-panel-title{
    position: absolute;
    display: block;
    text-align: center;
    right: 15px;
    h4{
      display: inline-block;
      line-height: 56px;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0;
      min-width: 255px;
      @include media-breakpoint-up(sm) {
        font-size: $h4-font-size;

      }
    }
    @include media-breakpoint-up(sm) {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }
  &, .rd-navbar-panel{
    height: $rd-navbar-fixed-height;
  }

  .rd-navbar-toggle {
    position: fixed;
    z-index: 17;
    left: 4px;
    top: 4px;
    @extend %rd-navbar-transition;
  }
}

  .rd-navbar-panel {
    background: $rd-navbar-light-sidebar-toggle-panel-background;
    box-shadow: $shadow-area-xs;
  }
  .rd-navbar-toggle {
    color: $rd-navbar-light-sidebar-toggle-panel-color;
  }

  @include media-breakpoint-down(xl) {
    .rd-navbar-toggle{
      @include toggle-lines-to-arrow($rd-navbar-light-sidebar-toggle-panel-lg-toggle-width, $rd-navbar-light-sidebar-toggle-panel-lg-toggle-size, $rd-navbar-light-sidebar-toggle-panel-lg-toggle-color);
    }
  }

  @include media-breakpoint-up(xxl) {
    .rd-navbar-panel{
      background-color: transparent;
      box-shadow: none;
    }
  }
